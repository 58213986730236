import React, { useState } from 'react'
import { Link, graphql, navigate } from 'gatsby'
import styled from 'styled-components'
import { Router, Location } from "@reach/router"
import { useMount } from 'react-use';

import { Layout, PrivateRoute } from '../components'
import { container, padding, bgImage, hoverState } from '../styles/global'
import { fetchData } from '../services/api';


const Preview = (props) => {
	const [data, setData] = useState(null);
	const [postType, setPostType] = useState(null);

	useMount(() => {
		const { id } = this.props;

		fetchData(`/pda/preview/${id}`)
			.then(response => {
				let data = response;

				this.setState({
					postType: data.post_type,
					data: data,
				})
			})
	})

	const resolveTemplate = () => {
		const templates = {
			// 'fieldnotes': FieldNotesArticle,
		}

		const Component = templates[postType];

		return (
			<Component
				previewData={data}
			/>
		)
	}

	return (
		<>
			{data && this.resolveTemplate()}
		</>
	)
}

const PreviewRouter = () => {
	return (
		<Router>
			<Preview
				path={`/preview/:id`}
			/>
		</Router>
	)
}

export default PreviewRouter

